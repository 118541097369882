import Venobox from "../libs/venobox.min";

const _window = $(window);
const body    = $('body');
import {goToByScroll} from './go-to-by-scroll';
import {closeMegaMenu} from "./mega-menu";
import {utils} from "./utils";


/**
 * Sticky buttons
 */
let stickyButtons = gsap.timeline();

stickyButtons.to('.btn--pill', {
	scrollTrigger: {
		trigger:             'footer',
		start:               'top 100%-=-8', // which means "when the top of the trigger hits -8px above the bottom of the viewport (40px button height - 32px bottom position = -8px)
		toggleActions:       'play none none reset',
		invalidateOnRefresh: true,
		//markers:             true
	},
	position:      'absolute',
	bottom:        $('#site-footer').outerHeight() + 32 // footer height + margin bottom
});


/**
 * Tabs
 */
$('span[data-tab]').off('click').on('click', function () {
	const tab = $(this).attr('data-tab');

	$('[data-tab="' + tab + '"]', '.tabs__header').addClass('active').siblings().removeClass('active');

	if (tab === 'all')
	{
		$('div[data-tab]', '.tabs__tabs').addClass('active');

		const currentURL = window.location.href;

		if (window.location.hash)
		{
			// remove hash from url
			window.history.pushState("", document.title, currentURL.split('#')[0]);
		}
	}
	else
	{
		const activeItem = $('div[data-tab="' + tab + '"]', '.tabs__tabs');
		activeItem.addClass('active');

		let inactiveItems = activeItem.siblings();
		inactiveItems.removeClass('active');
	}

	goToByScroll($('.tabs'), -16);
});


body.on('click.smoothscroll', 'a[href*="#tab-"]', function (e) {
	setTimeout(function () {
		let tab = location.hash.split('tab-');

		let target = $('span[data-tab="' + tab[1] + '"]');

		if (target.length > 0)
		{
			target.trigger('click');

			closeMegaMenu();
		}
	}, 10);
});


_window.on('load', function (event) {

	// smooth scroll to tab on load
	if (location.hash.includes('tab'))
	{
		let tab = location.hash.split('tab-');

		let target = $('span[data-tab="' + tab[1] + '"]');

		if (target.length > 0)
		{
			target.trigger('click');
		}
	}

});


if ($('.hero-foglia').length > 0)
{
	gsap.to(".hero-foglia", {
		opacity:       0,
		ease:          "none",
		scrollTrigger: {
			trigger: '.hero-foglia',
			start:   0,
			end:     $('.hero-foglia').height(),
			scrub:   true,
			//markers: true
		}
	});
}

new Venobox({
	selector:     '.venobox-3d',
	numeration:   false,
	overlayColor: 'rgba(0,0,0,0.85)',
	bgcolor:      'transparent',
	//maxWidth: '95%',
	onPostOpen: function (obj, gallIndex, thenext, theprev) {
		resizeIframe3d();
	},
});

new Venobox({
	selector:     '.btn[data-vbtype=iframe]',
	numeration:   false,
	overlayColor: 'rgba(0,0,0,0.85)',
	bgcolor:      'transparent',
	//maxWidth: '95%',
	onPostOpen: function (obj, gallIndex, thenext, theprev) {
		resizeHeroIframe();
	},
});


/**
 * Resize iframe inside venobox based on viewport size maintaining 16/9 aspect ratio
 */
function resizeHeroIframe() {
	let heroIframe = $('iframe', '.vbox-overlay.hero-iframe');

	if (_window.width() / _window.height() > 1.7777)
	{
		heroIframe.css('width', heroIframe.outerHeight() * 1.777777);
	}
}


/**
 * Resize iframe inside venobox based on viewport size maintaining 16/9 aspect ratio
 */
function resizeIframe3d() {
	let iframe3d = $('iframe', '.vbox-overlay.iframe-3d');

	if (_window.width() / _window.height() > 1.7777)
	{
		iframe3d.css('width', iframe3d.outerHeight() * 1.777777);
	}
}


/**
 * Calculate true viewport height without toolbars on mobile
 */
function calculateViewportHeight() {
	document.documentElement.style.setProperty('--viewport-height', window.visualViewport.height + 'px');
	document.documentElement.style.setProperty('--viewport-width', window.visualViewport.width + 'px');
}

calculateViewportHeight();

visualViewport.addEventListener('resizeEnd', () => {
	calculateViewportHeight();
});


/**
 * Custom checkboxes in WP Members forms
 */
$('form[id*="wpmem"] input[type=checkbox], form[id*="wpmem"] input[type=radio]').inputToToggle().each(function () {
	let id = $(this).attr('id');

	$(this).next('.toggle-control').wrap('<label for="' + id + '"></label>');
});


/**
 * Move buttons on mobile
 * @type {MediaQueryList}
 */
const breakpointMegamenu = window.matchMedia('(min-width: 992px)');

const breakpointMegamenuChecker = function () {

	// if larger viewport
	if (breakpointMegamenu.matches)
	{
		// remove the buttons from #site-navigation, insert them after #site-navigation and remove them from #mega-menu-user
		let headerButtons = $('.btn-appointment, .btn-profile', '#site-navigation').detach();
		$('#site-navigation').after(headerButtons);
		//$('.menu-buttons-mobile', '#mega-menu-user').empty();
	}
	// else if a small viewport
	else
	{
		// remove the buttons from the header and append them to #site-navigation and #mega-menu-user
		let headerButtons = $('.btn-appointment, .btn-profile', '#header').detach();
		headerButtons.appendTo([
			$('.menu-buttons-mobile', '#site-navigation'),
			//$('.menu-buttons-mobile', '#mega-menu-user')
		]);
	}
};

breakpointMegamenu.addEventListener('change', breakpointMegamenuChecker);

breakpointMegamenuChecker();


/**
 * Add selected documents to cookies
 */
let addDocumentsToList = function (e) {
	e.preventDefault();

	const form = e.target;
	const formBtn = form.querySelector('.add-documents-to-list');
	formBtn.setAttribute('disabled', 'disabled');

	const data = new FormData();
	data.append('action', 'wc_do_ajax');
	data.append('fn', 'add_documents');
	data.append('nonce', ajax_vars.nonce);

	const values = Array.from(form.querySelectorAll('input[type=checkbox]:checked')).map(item => item.value);

	if (values.length > 0)
	{
		data.append('values', JSON.stringify(values));

		fetch(ajax_vars.ajax_url, {
			method:      'POST',
			credentials: 'same-origin',
			body:       data
		})
			.then((response) => response.text())
			.then((data) => {
				if (data)
				{
					const parsedData = JSON.parse(data);

					if (parsedData.result === 'success')
					{
						const openPopupId = document.querySelector('.popup--active').id;
						closePopup(openPopupId);

						openPopup('documents-added-popup');
					}
				}
				else
				{
				}

				formBtn.removeAttribute('disabled');
			})
			.catch((error) => {
				console.error(error);
			});
	}
};

const addDocumentsToListForms = document.querySelectorAll('.add-documents-to-list-form');

if (addDocumentsToListForms !== null)
{
	addDocumentsToListForms.forEach((form) => {
		form.addEventListener('submit', addDocumentsToList);

		const formBtn    = form.querySelector('.add-documents-to-list');
		const checkboxes = form.querySelectorAll('input[type=checkbox]');

		checkboxes.forEach((el) => {
			el.addEventListener('change', function () {
				if (Array.from(checkboxes).some(x => x.checked))
				{
					formBtn.removeAttribute('disabled');
				}
				else
				{
					formBtn.setAttribute('disabled', 'disabled');
				}
			});
		});

	});
}


/**
 * Add single document to cookies
 */
let addSingleDocumentToList = function (e) {
	e.preventDefault();

	const btn = e.target;
	btn.classList.add('disabled');

	const data = new FormData();
	data.append('action', 'wc_do_ajax');
	data.append('fn', 'add_documents');
	data.append('nonce', ajax_vars.nonce);

	const values = [btn.dataset.documentValue];

	if (values.length > 0)
	{
		data.append('values', JSON.stringify(values));

		fetch(ajax_vars.ajax_url, {
			method:      'POST',
			credentials: 'same-origin',
			body:       data
		})
			.then((response) => response.text())
			.then((data) => {
				if (data)
				{
					const parsedData = JSON.parse(data);

					if (parsedData.result === 'success')
					{
						openPopup('documents-added-popup');
					}
				}
				else
				{
				}

				btn.classList.remove('disabled');
			})
			.catch((error) => {
				console.error(error);
			});
	}
};

const addSingleDocumentToListBtns = document.querySelectorAll('.add-single-document');

if (addSingleDocumentToListBtns !== null)
{
	addSingleDocumentToListBtns.forEach((el) => {
		el.addEventListener('click', addSingleDocumentToList);
	});
}



/**
 * Remove selected document from cookies
 */
let removeDocumentFromList = function (e) {
	e.preventDefault();

	const item = e.target;
	const documentId = item.dataset.documentId;

	removeDocumentBtns.forEach((el) => {
		el.classList.add('disabled');
	});

	const data = new FormData();
	data.append('action', 'wc_do_ajax');
	data.append('fn', 'remove_document');
	data.append('nonce', ajax_vars.nonce);
	data.append('id', documentId);

	fetch(ajax_vars.ajax_url, {
		method:      'POST',
		credentials: 'same-origin',
		body:       data
	})
		.then((response) => response.text())
		.then((data) => {
			if (data)
			{
				const parsedData = JSON.parse(data);

				if (parsedData.result === 'success')
				{
					utils.slideUp(item.closest('.tip-row'));

					// if this is the last item to be removed, hide the table and show the message
					if(parsedData.last_item === true)
					{
						setTimeout(function (){
							document.querySelector('.micro-content').classList.add('d-none');
							document.querySelector('.no-documents-message').classList.remove('d-none');
							utils.slideUp(document.querySelector('.send-documents-form-wrapper'));
						}, 500);
					}
				}
			}
			else
			{
			}

			removeDocumentBtns.forEach((el) => {
				el.classList.remove('disabled');
			});
		})
		.catch((error) => {
			console.error(error);
		});
};

const removeDocumentBtns = document.querySelectorAll('.doc-remove');

if (removeDocumentBtns !== null)
{
	removeDocumentBtns.forEach((el) => {
		el.addEventListener('click', removeDocumentFromList);
	});
}


/**
 * Send email with documents list
 */
let sendDocuments = function (e) {
	e.preventDefault();

	const form = e.target;
	const formBtn = form.querySelector('.btn');
	formBtn.setAttribute('disabled', 'disabled');

	const data = new FormData();
	data.append('action', 'wc_do_ajax');
	data.append('fn', 'send_documents');
	data.append('nonce', ajax_vars.nonce);
	data.append('post', utils.serializeForm(form));

	fetch(ajax_vars.ajax_url, {
		method:      'POST',
		credentials: 'same-origin',
		body:       data
	})
		.then((response) => response.text())
		.then((data) => {
			if (data)
			{
				const parsedData = JSON.parse(data);

				if (parsedData.result === 'success')
				{
					openPopup('documents-sent-success-popup');
				}
			}
			else
			{
				openPopup('documents-sent-error-popup');
			}

			formBtn.removeAttribute('disabled');
		})
		.catch((error) => {
			console.error(error);
		});
};

const sendDocumentsForm = document.querySelector('.send-documents-form');

if (sendDocumentsForm !== null)
{
	sendDocumentsForm.addEventListener('submit', sendDocuments);
}




/**
 * ON LOAD
 */

window.addEventListener('load', function (event) {

});



/**
 * ON RESIZE
 */

window.addEventListener('resize', function (event) {

	if (event.target === window)
	{
		// trigger resizeEnd event only when the window resizing ends
		if (this.resizeTO)
		{
			clearTimeout(this.resizeTO);
		}
		this.resizeTO = setTimeout(function () {
			const resizeEndEvent = new Event('resizeEnd');
			window.dispatchEvent(resizeEndEvent);
			visualViewport.dispatchEvent(resizeEndEvent);
		}, 200);
	}
});



/**
 * ON RESIZE END
 */

// do something when the window resizing ends
window.addEventListener('resizeEnd', function () {

	resizeIframe3d();

});



/**
 * ON SCROLL
 */
/*window.addEventListener('scroll', function() {
});*/